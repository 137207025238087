<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import Swal from "sweetalert2";
    import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Edit Pelabuhan",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Edit Pelabuhan",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Pelabuhan",
                        href: "/master/pelabuhan",
                    },
                    {
                        text: "Edit Pelabuhan",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                mp_id: this.$route.params.mp_id,
                master_negara: [],
                mp_nama: null,
                mp_code_nama: null,
                mp_code: null,
                mp_lokasi: null,
                mp_ps_code: null,
                negara_selected: null,
            };
        },
        mounted() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            // Select Chain Master Profinsi
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-negara",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then((response) => {
                    this.master_negara = response.data.data.referensi;
                    Swal.close();
                })
                .catch(e => {
                    this.axiosCatchError = e.response.data.data
                    console.log(this.axiosCatchError)
                    Swal.close();
                })
            // Get Data Detail
            axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "master/pelabuhan", {
                params:{
                    mp_id: this.mp_id
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if(response_data.meta.code == 200){
                    var data_edit = response_data_fix.data[0];
                    self.mp_nama = data_edit.mp_nama;
                    self.mp_code_nama = data_edit.mp_code_nama;
                    self.mp_code = data_edit.mp_code;
                    self.mp_lokasi = data_edit.mp_lokasi;
                    self.mp_ps_code = data_edit.mp_ps_code;
                    self.negara_selected = data_edit.negara;
                    Swal.close();
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response_data_fix.data.message
                    });
                }
            })
            .catch(e => {
                this.axiosCatchError = e.response.data.data
                console.log(this.axiosCatchError)
            })
        },
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            StoreData() {
                let self = this;
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
                axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "master/pelabuhan", {
                        mp_nama: self.mp_nama,
                        mp_code_nama: self.mp_code_nama,
                        mp_code: self.mp_code,
                        mp_lokasi: self.mp_lokasi,
                        mp_ps_code: self.mp_ps_code,
                        mp_mn_id: self.negara_selected?.mn_id,
                    },
                    {headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    }
                })
                .then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master pelabuhan segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-pelabuhan" });
                        }
                    });
                }).catch(e => {
                    this.axiosCatchError = e.response.data.data
                    Swal.close();
                })
			},
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                {{axiosCatchError}}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0]}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama Pelabuhan" label-for="formrow-mp_nama-input">
                                        <b-form-input id="formrow-mp_nama-input" type="text" v-model="mp_nama"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Kode Nama Pelabuhan" label-for="formrow-mp_code_nama-input">
                                        <b-form-input id="formrow-mp_code_nama-input" type="text" v-model="mp_code_nama"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Kode Pelabuhan" label-for="formrow-mp_code-input">
                                        <b-form-input id="formrow-mp_code-input" type="text" v-model="mp_code"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Pelabuhan Lokasi" label-for="formrow-mp_lokasi-input">
                                        <b-form-input id="formrow-mp_lokasi-input" type="text" v-model="mp_lokasi"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Postal Code" label-for="formrow-mp_ps_code-input">
                                        <b-form-input id="formrow-mp_ps_code-input" type="text" v-model="mp_ps_code"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Negara" label-for="formrow-mp_mn_id-input">
                                        <v-select :options="master_negara" label="mn_nama" v-model="negara_selected"></v-select>
                                    </b-form-group> 
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
